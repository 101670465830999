<template>
    <div class="row">
      <div class="col-12">
        <h3 class=" Networks_Brands">We compare the top broadband, TV and mobile phone providers</h3>
      </div>
      <div class="col-md-12 mt-5 Networks_Brands_img">
        <ul class="logo_strip_img">
          <div class="row justify-content-center">
            <template v-for="(networks, i) in networks">
              <li class="col-xs-4 col-md-2 mb-3" :key="networks.title + i" v-if="networks.popular == 1">
                <router-link :to=" '/networks/' + networks.slug + '?network=' + networks.id " tag="a" >
                  <img :src="publicPath+ networks.img"/>
                </router-link>
              </li>
            </template>
          </div>
          
        </ul>
      </div>
    </div>
</template>

<script>
export default {
  name: "networkCopm",
  data() {
  return {
      publicPath: "https://app.contractsexpert.co.uk/storage/app/public/",
      networks: [],
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    getFullPath() {
      return this.$route.path;
    }
  },
  watch: {
    getFullPath() {
      this.getData();
    }
  },
  methods: {
    getData() {
      this.loading = true;
      this.$urlmain
        .get("network-popular.json")
        .then(response => {
          this.networks = response.data.data;
          response => (this.networks = response.data.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    }
  }
};
</script>

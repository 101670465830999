
<template>
    <div class="row" v-show=" this.$route.params.id !== 'Mobile_Broadbands' && this.$route.params.id !== 'Sim_Only' ">
      <div class="col-12">
        <h3 class="Networks_Brands mt-5 mt-md-0">Brands</h3>
      </div>
      <div class="col-md-12 mt-5 Networks_Brands_img">
        <ul class="logo_strip_img">
            <div class="row justify-content-center">
            <template v-for="(brand, i) in brands">
              <li class="col-xs-4 col-md-2 mb-3" :key="brand.title + i">
                <router-link :to="'/categories/' + returnCat + '/' + brand.slug" tag="a" >
                  <img :src="publicPath+ brand.img"/>
                </router-link>
              </li>
            </template>
          </div>
          
        </ul>
      </div>
    </div>
</template>
<script>
export default {
  name: "bybrandsComp",
  data() {
    return {
      brands: [],
      publicPath: "https://app.contractsexpert.co.uk/storage/app/public/"
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    getFullPath() {
      return this.$route.path;
    },
    returnCat() {
      return this.$route.params.id;
    }
  },
  watch: {
    getFullPath() {
      this.getData();
    }
  },
  methods: {
    getData() {
      this.loading = true;
      this.$urlmain
        .get("brands-popular.json")
        .then(response => {
          this.brands = response.data.data;
          response => (this.brands = response.data.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    }
  }
};
</script>

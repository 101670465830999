<template>
  <!-- blogs start -->
  <div class="blogs-page">
    <PageLoader />
    <div class="row phones m-0">
      <!-- :style="{ backgroundImage: `url('${singleCategorys.banner ? publicPath + singleCategorys.banner : ''}')` }" -->
      <div class="img1" > 
        <img :src="singleCategorys.banner ? publicPath + singleCategorys.banner : ''" style="width: 100%;height: auto;">
          <!-- <div class="img1all text-center text-white">
          </div>   -->
      </div>
    </div>
       <div class="container">
        <div class="row Networks_phones text-center">
            <div class="col-12">
              <div class="row">
                <div class="col-lg-12">
                  <byNetworkComp />
                </div>
                <div class="col-lg-12">
                  <div class="row">
                    <div class="col-12 mt-5 Networks_Brands_img">
                        <byBrandsComp />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    <!-- <div class="container-fluied">
      <div class="row Networks_phones text-center m-0">
        <div class="col-12">
          <div class="row">
            <div :class="cat_prams.toLowerCase() == 'Sim_Only'.toLowerCase() || cat_prams.toLowerCase() == 'Mobile_Broadbands'.toLowerCase() ? 'col-md-12' : 'col-md-6'">
              <byNetworkComp />
            </div>
            <byBrandsComp />
          </div>
        </div>
      </div>
    </div> -->
    
    <div class="container">
      <ul class="row mt-5 mb-4 justify-content-start" v-if="cat_prams == 'Sim_Only' || cat_prams == 'Mobile_Broadbands'">
        <div class="col-12 mt-5">
          <h2 class="">Popular {{ singleCategorys.title }} Deals</h2>
        </div>
        <template v-for="singleCategory in singleCategorys.products">
          <template  v-for="offers in singleCategory.offers">
            <li  :key="offers.title + i" class="list-group-item col-md-4 text-left border-0">
              <router-link
                :to="
                  '/categories/' +
                    cat_prams +
                    '/' +
                    singleCategory.brand.slug +
                    '/' +
                    singleCategory.slug +
                    '?product=' +
                    singleCategory.id
                "
                tag="a"
                class="border-bottom border-top-0 border-left-0 border-right-0 d-block w-100 h-100 px-2 py-2"
                rel="nofollow"
                >{{ offers.title }}</router-link>
            </li>
          </template>
        </template>
      </ul>
      <ul class="row mt-5 mb-4 justify-content-start" v-else>
        <div class="col-12 mt-5">
          <h2 class="">Popular {{ singleCategorys.title }} Deals</h2>
        </div>
        <template v-for="singleCategory in singleCategorys.products">
          <li  :key="singleCategory.title + i" class="list-group-item col-md-4 text-left border-0">
            <router-link
              :to="
                '/categories/' +
                  cat_prams +
                  '/' +
                  singleCategory.brand.slug +
                  '/' +
                  singleCategory.slug +
                  '?product=' +
                  singleCategory.id
              "
              tag="a"
              class="border-bottom border-top-0 border-left-0 border-right-0 d-block w-100 h-100 px-4 py-2"
              rel="nofollow"
              >{{ singleCategory.title }}</router-link>
          </li>
        </template>
      </ul>
    </div>

    <template v-if="singleCategorys.content == 1">
      <section id="contents">
        <div class="description description_sec pt-90 mt-5 pt-5 description_section">
          <div class="container">
            <div class="row m-0">
              <div class="col-lg-8 desc_body">
                <div
                  v-for="(content, i) in this.singleCategorys.contents"
                  :key="i"
                  :id="'tab-' + content.title"
                  class="mt-20 pb-20"
                >
                  <h3 class="text-left mb-40 pt-4">{{ content.title }}</h3>
                  <div v-html="content.desc"></div>
                  <div class="mt-20" style="border:solid 2px gray"></div>
                </div>
              </div>
              <div class="col-lg-4 Networks_Brands_img">
              <div class="row bg_sky m-auto pb-5">
                <div class="network col-lg-12 box_shadow pt-4">
                    <h4 class="pb-1 pt-3 pb-2">Our Top Providers</h4>
                  <div class="row justify-content-center">
                    <template v-for="(network, i) in networks" >
                      <router-link :key="network.title + i" :to=" '/networks/' + network.slug + '?network=' + network.id " tag="a" 
                        class="network-sub-product brand_phone brand_product">
                        <img @click="filnetworkAdd(network.id)" :src="publicPath + network.img " alt>
                      </router-link>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </section>
    </template>
  </div>
</template>
<script>
import byNetworkComp from "@/views/pages/pagesComponents/network.vue";
import byBrandsComp from "@/views/pages/categories/internal/components/bybrand.vue";
import PageLoader from "@/components/units/PageLoader.vue";
import { required, minLength } from "vuelidate/lib/validators";
export default {
  name: "newsCopm",
  components: {
    byNetworkComp,
    byBrandsComp,
    // simOnlyComp,
    // sideBarComp,
    PageLoader
  },
  data() {
    return {
      singleCategorys: [],
      popularCat: [],
      networks: [],
      publicPath: "https://app.contractsexpert.co.uk/storage/app/public/"
    };
  },
  validations: {
    search: {
      required,
      minLength: minLength(3)
    }
  },
  mounted() {
   window.scrollTo(0, 0)
    this.getData();
    this.getPopCat();
    this.getNetwork();
  },
  computed: {
    getFullPath() {
      return this.$route.path;
    },
    cat_prams() {
      return this.$route.params.id;
    }
  },
  watch: {
    getFullPath() {
      this.getData();
    }
  },
  methods: {
    getData() {
      this.loading = true;
      this.$http
        .get("category/" + this.$route.params.id)
        .then(response => {
          this.singleCategorys = response.data.data;
          response => (this.singleCategorys = response.data.data);
          console.log(this.singleCategorys.products)
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    },
    getPopCat() {
      this.loading = true;
      this.$urlmain
        .get("category-popular.json")
        .then(response => {
          this.popularCat = response.data.data;
          response => (this.popularCat = response.data.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    },
    getNetwork() {
      this.loading = true;
      this.$urlmain
        .get("network-popular.json")
        .then(response => {
          this.networks = response.data.data;
          response => (this.networks = response.data.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    }
  }
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"blogs-page"},[_c('PageLoader'),_c('div',{staticClass:"row phones m-0"},[_c('div',{staticClass:"img1"},[_c('img',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":_vm.singleCategorys.banner ? _vm.publicPath + _vm.singleCategorys.banner : ''}})])]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row Networks_phones text-center"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('byNetworkComp')],1),_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mt-5 Networks_Brands_img"},[_c('byBrandsComp')],1)])])])])])]),_c('div',{staticClass:"container"},[(_vm.cat_prams == 'Sim_Only' || _vm.cat_prams == 'Mobile_Broadbands')?_c('ul',{staticClass:"row mt-5 mb-4 justify-content-start"},[_c('div',{staticClass:"col-12 mt-5"},[_c('h2',{},[_vm._v("Popular "+_vm._s(_vm.singleCategorys.title)+" Deals")])]),_vm._l((_vm.singleCategorys.products),function(singleCategory){return [_vm._l((singleCategory.offers),function(offers){return [_c('li',{key:offers.title + _vm.i,staticClass:"list-group-item col-md-4 text-left border-0"},[_c('router-link',{staticClass:"border-bottom border-top-0 border-left-0 border-right-0 d-block w-100 h-100 px-2 py-2",attrs:{"to":'/categories/' +
                  _vm.cat_prams +
                  '/' +
                  singleCategory.brand.slug +
                  '/' +
                  singleCategory.slug +
                  '?product=' +
                  singleCategory.id,"tag":"a","rel":"nofollow"}},[_vm._v(_vm._s(offers.title))])],1)]})]})],2):_c('ul',{staticClass:"row mt-5 mb-4 justify-content-start"},[_c('div',{staticClass:"col-12 mt-5"},[_c('h2',{},[_vm._v("Popular "+_vm._s(_vm.singleCategorys.title)+" Deals")])]),_vm._l((_vm.singleCategorys.products),function(singleCategory){return [_c('li',{key:singleCategory.title + _vm.i,staticClass:"list-group-item col-md-4 text-left border-0"},[_c('router-link',{staticClass:"border-bottom border-top-0 border-left-0 border-right-0 d-block w-100 h-100 px-4 py-2",attrs:{"to":'/categories/' +
                _vm.cat_prams +
                '/' +
                singleCategory.brand.slug +
                '/' +
                singleCategory.slug +
                '?product=' +
                singleCategory.id,"tag":"a","rel":"nofollow"}},[_vm._v(_vm._s(singleCategory.title))])],1)]})],2)]),(_vm.singleCategorys.content == 1)?[_c('section',{attrs:{"id":"contents"}},[_c('div',{staticClass:"description description_sec pt-90 mt-5 pt-5 description_section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col-lg-8 desc_body"},_vm._l((this.singleCategorys.contents),function(content,i){return _c('div',{key:i,staticClass:"mt-20 pb-20",attrs:{"id":'tab-' + content.title}},[_c('h3',{staticClass:"text-left mb-40 pt-4"},[_vm._v(_vm._s(content.title))]),_c('div',{domProps:{"innerHTML":_vm._s(content.desc)}}),_c('div',{staticClass:"mt-20",staticStyle:{"border":"solid 2px gray"}})])}),0),_c('div',{staticClass:"col-lg-4 Networks_Brands_img"},[_c('div',{staticClass:"row bg_sky m-auto pb-5"},[_c('div',{staticClass:"network col-lg-12 box_shadow pt-4"},[_c('h4',{staticClass:"pb-1 pt-3 pb-2"},[_vm._v("Our Top Providers")]),_c('div',{staticClass:"row justify-content-center"},[_vm._l((_vm.networks),function(network,i){return [_c('router-link',{key:network.title + i,staticClass:"network-sub-product brand_phone brand_product",attrs:{"to":'/networks/' + network.slug + '?network=' + network.id,"tag":"a"}},[_c('img',{attrs:{"src":_vm.publicPath + network.img,"alt":""},on:{"click":function($event){return _vm.filnetworkAdd(network.id)}}})])]})],2)])])])])])])])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }